<template>
    <div>
        <Loading :loading="loading"/>
        <Edit
            :tipo="tipoSelecionado"
            :campos="camposEditados"
            :formatos="formatos"
            :close="closeEdit"
            :onSubmit="onSubmit"
        />
        <CAlert show color="light">
            Campos adicionais são utilizados nos formulários de cadastro dos sistemas Versotech.
            Essa ferramenta pode funcionar em conjunto dos campos de integração.
        </CAlert>
        <CTabs variant="tabs" class="nav-tabs-boxed">
            <CTab title="Campos adicionais">
                <ListaCampos
                    :tipos="tipos"
                    :campos="campos"
                    :onEdit="openEdit"
                />
            </CTab>
        </CTabs>
    </div>
</template>

<script>
  import { get, put } from '../../helpers/apiRequest';
  import ListaCampos from "./components/ListaCampos";
  import clone from "just-clone";
  import Edit from "../CamposAdicionais/components/Edit.vue";

  export default {
    name: "CamposAdicionais",
    components: {
      ListaCampos,
      Edit
    },
    data() {
      return {
        'loading': false,
        'tipos': [],
        'formatos': [],
        'campos': [],
        'tipoSelecionado': {},
        'camposEditados': [],
      };
    },
    methods: {
      openEdit(entidade) {
        this.tipoSelecionado = clone(this.tipos[entidade]);
        this.tipoSelecionado.chave = entidade;
        this.camposEditados  = this.campos[entidade] ? clone(this.campos[entidade]) : [];
        this.$modal.show('editCampoAdicional');
      },
      // carrega as entidades
      loadTipos() {
        get(`/admin/tipos-campos-adicionais`)
          .then((response) => this.tipos = response);
      },
      closeEdit() {
        this.$modal.hide('editCampoAdicional');
      },
      // carrega os campos adicionais que estão cadastrados
      loadCampos() {
        get(`/admin/campos-adicionais`)
          .then((response) => this.campos = response);
      },
      // carrega os possíveis formatos de campo para cadastro
      loadFormatos() {
        get(`/admin/formatos-campos-adicionais`)
            .then((response) => this.formatos = response);
      },
      onSubmit(tipo, campos) {
        this.loading = true;
        put(`/admin/campos-adicionais/${this.tipoSelecionado.chave}`, campos)
            .then((response) => {
              this.$swal({
                title: 'Sucesso!',
                text: response.message,
                icon: 'success',
              });
              this.loadCampos();
              this.closeEdit();
              this.loading = false;
            })
            .catch((err) => {
              this.$swal({
                title: 'Ops!',
                text: err.message,
                icon: 'error',
              });
              this.loading = false;
            })
      }
    },
    beforeMount() {
      this.loadTipos();
      this.loadCampos();
      this.loadFormatos();
    }
  }
</script>
