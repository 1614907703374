<template>
  <div>
    <modal
        name="editCampoAdicional"
        height="auto"
        width="1300"
        :scrollable="true"
        :clickToClose="false"
    >
    <CampoAdicionalOpcoes :campoEditado="campoEditado"/>
    <CampoAdicionalSql    :campoEditado="campoEditado" ref="campoAdicionalSql"/>
    <CloseModal           :close="close"/>

      <div class="modalbox query-tarefa">
        <div class="modalbox-content">
          <CTabs variant="tabs" class="nav-tabs-boxed">
            <CTab :title="tipo.titulo">
              <CCard v-if="campos.length === 0">
                <CCardBody>
                  <div class="row">
                    <div class="col text-center">
                      😕 Ops! Parece que você não tem nenhum campo adicional criado, clique no botão abaixo para
                      mapear seu primeiro campo.
                    </div>
                  </div>
                </CCardBody>
              </CCard>
              <CCard
                  v-for="(campo, indice) in campos"
                  :key="indice"
                  :class="{
                  'border-danger':
                    campo.label.trim().length === 0 ||
                    campo.formato.trim().length === 0,
                }"
              >
                <CCardBody style="min-height: 151px;">
                  <div class="row form-group">
                    <div class="col col-2">
                      <label>Título</label>
                      <input
                          class="form-control"
                          type="text"
                          v-model="campo.label"
                          :class="{
                          'is-invalid': campo.label.trim().length === 0,
                        }"
                      />
                    </div>
                    <div class="col col-2">
                      <label>Chave</label>
                      <input
                          class="form-control"
                          type="text"
                          v-model="campo.indice"
                          :class="{'is-invalid': campo.label.trim().length === 0}"
                          @input="(event) => { campo.indice = event.target.value.replace(/ /g, '_') }"
                      />
                    </div>
                    <div class="col-1">
                      <label>Ordem</label>
                      <input 
                        class="form-control" 
                        type="number" 
                        v-model="campo.ordem"
                      >
                    </div>
                    <div class="col col-2">
                      <label>Formato</label>
                      <div class="input-with-button">
                      <select class="form-control"
                              :class="{'is-invalid': campo.formato.trim().length === 0}"
                              v-model="campo.formato"
                              @change="handleChangeFormatoField(campo)">
                        <option
                            v-for="formato in formatos"
                            :value="formato.key"
                        >
                          {{ formato.label }}
                        </option>
                      </select>
                      <button
                          v-if="checkCampoAtivo(formatos, campo.formato, 'options')"
                          class="mt-1"
                          @click="openModalOpcoes(campo)"
                      >
                        Adicionar opções
                      </button>
                    </div>
                  </div>
                    <div class="col col-1">
                      <label>Tipo opção</label>
                      <select class="form-control"
                              :disabled="!isMultiOpcoes(campo.formato)"
                              v-model="campo.usa_sql">
                        <option value="0">Texto</option>
                        <option value="1">Query</option>
                      </select>
                    </div>
                    <div class="col-1">
                      <label>Tamanho</label>
                      <input
                          :disabled="!checkCampoAtivo(formatos, campo.formato, 'size')"
                          class="form-control"
                          type="number"
                          v-model="campo.tamanho"
                          :class="{
                          'is-invalid':
                            campo.tamanho ? campo.tamanho.trim().length === 0 : true &&
                            checkCampoAtivo(formatos, campo.formato, 'size'),
                        }"
                      />
                    </div>
                    <div class="form-group col-1">
                      <label>Obrigatório</label>
                      <toggle-button
                          :value="Boolean(campo.obrigatorio)"
                          @change="
                          campo.obrigatorio = Boolean(!campo.obrigatorio)
                        "
                          :labels="{
                          checked: 'Sim',
                          unchecked: 'Não',
                        }"
                          :color="{
                          checked: '#3d9c56',
                          unchecked: '#c75d5d',
                          disabled: '#CCCCCC',
                        }"
                          :width="50"
                      />
                    </div>
                    <div class="form-group col">
                      <label>Aplicar nos sistemas</label>
                      <label class="checkbox">
                        <input type="checkbox" v-model="campo.aplicab2b" />
                        &ensp; B2B
                      </label>
                      <label class="checkbox">
                        <input
                            type="checkbox"
                            v-model="campo.aplicacallcenter"
                        />
                        &ensp; Callcenter
                      </label>
                      <label class="checkbox">
                        <input type="checkbox" v-model="campo.aplicafv" />
                        &ensp; Força de vendas
                      </label>
                    </div>
                    <div>
                      <button
                          type="button"
                          name="button"
                          class="btn-sm btn-danger"
                          v-on:click="onRemoveCampo(indice)"
                      >
                        <fa-icon icon="trash-alt"></fa-icon>
                      </button>
                    </div>
                  </div>
                </CCardBody>
              </CCard>
            </CTab>
          </CTabs>
        </div>
      </div>
      <div class="modal-footer">
        <button class="button-success button" @click="onAdd">
          Incluir campo
        </button>
        <button
            :class="{ 'button button-success': true }"
            v-on:click="handleSubmit"
        >
          Atualizar
        </button>
        <button class="button" v-on:click="close">Fechar</button>
      </div>
    </modal>
  </div>
</template>

<script>
import CloseModal from "../../../components/CloseModal";
import CampoAdicionalOpcoes from "./CampoAdicionalOpcoes.vue";
import CampoAdicionalSql from "./CampoAdicionalSql.vue";
import _find from "lodash/find";

const novoCampo = (tipo) => ({
  label: "",
  formato: "",
  banco_opcao: "LOCAL",
  tamanho: "",
  obrigatorio: 0,
  aplicab2b: 0,
  ordem:'',
  usa_sql: 0,
  aplicacallcenter: 0,
  aplicafv: 0,
  entidade: tipo,
  campo_adicional_opcoes: [],
});

export default {
  name: "Edit",
  components: {
    CloseModal,
    CampoAdicionalOpcoes,
    CampoAdicionalSql
  },
  data() {
    return {
      campoEditado: [],
    };
  },
  props: ["close", "tipo", "campos", "formatos", "onSubmit"],
  methods: {
    handleSubmit() {
      this.onSubmit(this.tipo.chave, this.campos);
    },
    checkCampoAtivo(configFormatos, formatoSelecionado, propriedade) {
      let propriedadesFormato = _find(configFormatos, {key: formatoSelecionado});
      return propriedadesFormato ? propriedadesFormato[propriedade] : false;
    },
    onAdd() {
      this.campos.push(novoCampo(this.tipo.chave));
    },
    capturarInput(inputValue) {
      this.campos.query = inputValue;
    },
    onRemoveCampo(indice) {
      this.campos.splice(indice, 1);
    },
    isMultiOpcoes(formato) {
     return formato == 'select' || formato == 'checkbox' || formato == 'multiselect' || formato == 'radio' ? true : false;
    },
    openModalOpcoes(campo) {
      this.campoEditado = campo;

      if (campo.usa_sql == 1) {
        this.$modal.show("campoAdicionalSql");
      } else {
        this.$modal.show("campoAdicionalOpcoes");
      }
    },
    handleChangeFormatoField(campo){
      campo.tamanho = null
      campo.campo_adicional_opcoes = [];
    },
  },
};
</script>
<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>