<template>
  <modal
      name="campoAdicionalSql"
      height="auto"
      width="800"
      :scrollable="true"
      :clickToClose="false"
  >
  <CloseModal :close="closeModal" />
    <div class="modalbox">
      <div class="modalbox-content">
        <CCard>
          <CCardBody>
            <div class="row">
              <div class="col">
                <div class="form-group">
                    <h4>Query a ser executada</h4>
                </div>
                <div class="row">
                  <div class="form-group col-6">
                      <label>Local de execução da Query</label>
                      <select class="form-control" v-model="campoEditado.banco_opcao">
                          <option value="LOCAL">Banco de dados Versotech</option>
                          <option value="ERP">Banco de dados ERP</option>
                      </select>
                  </div>
                </div>
                <div class="codemirror">
                    <codemirror v-model="campoEditado.query" :options="code_options"></codemirror>
                </div>
                
                <div><br>
                    <h5>Atenção</h5>
                    <small>
                      Este campo é utilizado preenchimento de opções a serem listadas quando o parâmetro é dos tipos: <strong>"SELECT", "CHECKBOX", "MULTISELECT" e "RADIO"</strong>. 
                      Para utilização do formato SQL, nota-se que estas consultas são executadas utilizando o banco de dados da Versotech ou banco de dados ERP, 
                      ao executar uma consulta SQL, deve-se retornar 2 aliases de nome <strong>"chave" e "valor"</strong>, o campo informado pela <strong>"chave"</strong> será o 
                      utilizado para envio no parâmetro, enquanto que <strong>"valor"</strong> será apenas para exibição. 
                    </small><br><br>
                    <small><strong>⚠️ Cuidado, a consulta deve ser feita pensando em sua performance no geral, grandes consultas ocasionarão lentidão no sistema.</strong></small>
                </div>
        </div>
            </div>
          </CCardBody>
        </CCard>
      </div>
      <div class="modal-footer">
        <button
            class="button-success button w-25 float-right"
            v-on:click="onSubmit"
        >
          Salvar
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
import CloseModal from "../../../components/CloseModal";
import { codemirror } from 'vue-codemirror'
import _remove from "lodash/remove";

export default {
  emits: ['update:campoEditado'],
  name: "CampoAdicionalSql",
  props: ["campoEditado"],
  data() {
    return {
      code_options: {
        tabSize: 4,
        styleActiveLine: true,
        lineNumbers: true,
        line: true,
        mode: 'text/x-sql',
        autoRefresh:true,
        lineWrapping: true,
        theme: 'monokai',
        showCursorWhenSelecting: true,
        highlightSelectionMatches: {
          showToken: /\w/,
          annotateScrollbar: true
        },
      }
    }
  },
  components: {
    codemirror,
    CloseModal,
  },
  methods: {
    closeModal() {
      this.$modal.hide("campoAdicionalSql");
    },
    onSubmit() {
      this.campoEditado.campo_adicional_opcoes = [];
      this.closeModal();
    },
  },
  beforeMount() {
  
  },
};
</script>

<style scoped></style>