<template>
    <table class="table table-hover table-sm">
        <tr>
            <th>Tipo</th>
            <th>Campos criados</th>
            <th></th>
        </tr>
        <tr v-for="(atributos, entidade)  in tipos">
          <td>
            <strong> {{ atributos.titulo }}</strong>
            <br>
            <small> Chave: {{ entidade }} </small>
          </td>
          <td> {{ campos[entidade] ? campos[entidade].length : 0 }} </td>
          <td class="text-right">
            <button
                type="button"
                class="button button-primary button-sm"
                @click="onEdit(entidade)"
            >
              <fa-icon icon="pencil-alt" />
            </button>
          </td>
        </tr>
    </table>
</template>
<script>
  export default {
    name: "ListaCampos",
    props: [
      'tipos',
      'campos',
      'onEdit'
    ]
  }
</script>
